import React, { useState } from "react";
import "./TransportTable.scss";
import DataTable, { TableColumn } from "react-data-table-component";
import { ReactComponent as InputSearch } from "./svg/InputSearch.svg";
import { ReactComponent as DeleteFunction } from "./svg/deleteFunction.svg";
import { ReactComponent as HistoryFunction } from "./svg/history.svg";
import { customStyles } from "./styles";
import { TablePropsType } from "./TransportTableTypes";
import { FunctionDTO } from "../../../api/auth/apiClient";
import { NameDataType } from "./functions";
import Toggle from "./Toggle/Toggle";
import { useIntl } from "react-intl";
import FunctionHistory from "../FunctionHistory/FunctionHistory";

type PaginationComponentOptionsType = {
  rowsPerPageText: string;
  rangeSeparatorText: string;
  selectAllRowsItem: boolean;
  selectAllRowsItemText: string;
};

function TransportTable(props: TablePropsType) {
  const [hexSelected, setHexEnabled] = useState(false);
  const [activeInput, setActiveInput] = useState(false);
  const [showFunctionHistory, setShowFunctionHistory] = useState(false);
  const intl = useIntl();
  const StType = [
    {
      label: `\u{1F534} ${intl.formatMessage({ id: "status_error_table" })}`,
      value: "Failed",
    },
    {
      label: `\u{1F7E2} ${intl.formatMessage({ id: "status_success_table" })}`,
      value: "Success",
    },
    {
      label: `\u{1F535} ${intl.formatMessage({
        id: "status_undefined_table",
      })}`,
      value: "Undefined",
    },
  ];
  const paginationComponentOptions: PaginationComponentOptionsType = {
    rowsPerPageText: intl.formatMessage({ id: "table_page_text" }),
    rangeSeparatorText: "-",
    selectAllRowsItem: true,
    selectAllRowsItemText: intl.formatMessage({ id: "table_select_allPages" }),
  };
  const columns: TableColumn<FunctionDTO>[] = [
    {
      name: intl.formatMessage({ id: "group" }),
      selector: (row) =>
        props.functionGroups.find((f) => f.id === row.groupId)?.groupName ??
        "неизвестная группа",
      center: true,
      sortable: true,
      wrap: true,
      compact: true,
      grow: 6,
      style: { textAlign: "center" },
    },
    {
      name: intl.formatMessage({ id: "name" }),
      selector: (row) => row.name!,
      center: true,
      sortable: true,
      compact: true,
      wrap: true,
      grow: 5,
    },
    {
      name: intl.formatMessage({ id: "data_type" }),
      selector: (row) =>
        NameDataType.find((n) => n.value === row.dataType)?.label ?? "",
      sortable: true,
      compact: true,
      grow: 4,
      center: true,
    },
    {
      name: intl.formatMessage({ id: "data_byte" }),
      selector: (row) => row.dataByte! + 1,
      compact: true,
      center: true,
      wrap: true,
      grow: 4,
    },
    {
      name: intl.formatMessage({ id: "data_bit" }),
      selector: (row) => row.dataBit ?? "-",
      center: true,
      wrap: true,
      compact: true,
      grow: 4,
    },
    {
      name: intl.formatMessage({ id: "can_id" }),
      selector: (row) => row.canId,
      center: true,
      sortable: true,
      wrap: true,
      compact: true,
      grow: 5,
    },
    {
      name: intl.formatMessage({ id: "status" }),
      selector: (row) =>
        StType.find((s) => s.value === row.status?.name)?.label ?? "-",
      sortable: true,
      wrap: true,
      grow: 4,
    },
    {
      name: intl.formatMessage({ id: "date" }),
      selector: (row) => {
        const date = new Date(`${row.date}`);
        return date.toLocaleString("ru-Ru");
      },
      sortable: true,
      wrap: true,
      grow: 4,
    },
    {
      name: intl.formatMessage({ id: "result" }),
      selector: (row) =>
        hexSelected ? (row.hexResult ?? "-") : (row.result ?? "-"),
      center: true,
      wrap: true,
      compact: true,
      grow: 4,
    },
    {
      name: intl.formatMessage({ id: "action" }),
      cell: (row) => (
        <>
          <DeleteFunction
            className="mr-1"
            onClick={() => props.onShowDeleteFunction(row)}
          />
          <HistoryFunction
            onClick={() => {
              props.setSelectedRow(row);
              setShowFunctionHistory(true);
            }}
          />
        </>
      ),
      compact: true,
      center: true,
      grow: 4,
    },
  ];
  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newData = props.functions.filter((row) => {
      return (
        props.functionGroups
          .find((f) => f.id === row.groupId)
          ?.groupName?.toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        row.name?.toLowerCase().includes(event.target.value.toLowerCase())
      );
    });
    props.setFilterFunctions(newData);
  };

  const handleRowClicked = (
    row: FunctionDTO,
    event: React.MouseEvent<Element, MouseEvent>
  ) => {
    props.onChangeShowEditorTable(true);
    props.setSelectedRow({ ...row } as FunctionDTO);

    const selectedFormat = row.spn ? "j1939" : "Standard";
    props.setFormat(selectedFormat);
  };

  return (
    <>
      {showFunctionHistory ? (
        <div>
          <FunctionHistory
            setShowFunctionHistory={setShowFunctionHistory}
            selectedRow={props.selectedRow}
            showNotification={props.showNotification}
            onChangeNotificationState={props.onChangeNotificationState}
          />
        </div>
      ) : null}
      <div className="tableContainer">
        <div className="flex ml-7">
          <div className={`searchBlock ${activeInput ? "active" : ""}`}>
            <input
              type="text"
              placeholder={intl.formatMessage({ id: "tableSearch" })}
              onChange={handleFilter}
              onClick={() => {
                setActiveInput(!activeInput);
              }}
            />
            <InputSearch />
          </div>
          <div className="ml-auto mr-8 mt-7">
            <Toggle hexSelected={hexSelected} setHexEnabled={setHexEnabled} />
          </div>
        </div>
        <div className="tableItems mx-8">
          <DataTable
            columns={columns}
            data={props.filterFunctions}
            noDataComponent={intl.formatMessage({ id: "empty_table" })}
            fixedHeader
            fixedHeaderScrollHeight="300px"
            pagination
            paginationComponentOptions={paginationComponentOptions}
            striped
            highlightOnHover
            pointerOnHover
            persistTableHead
            customStyles={customStyles}
            onRowClicked={handleRowClicked}
          />
        </div>
      </div>
    </>
  );
}
export default TransportTable;
